import { animalPieceTypes } from "../const";
import { allPieceTypes } from "../const";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  MoveType,
  ISquare,
  Effect,
  SideEffect,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class RhinoMovement extends BaseMovement implements PieceMovement {
  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    // Lane ability
    if (!piece.hasMoved) {
      const leftPosition = { col: position.col - 1, row: position.row };
      const rightPosition = { col: position.col + 1, row: position.row };
      const leftPiece = this.getPieceAtPosition(leftPosition, boardState);
      const rightPiece = this.getPieceAtPosition(rightPosition, boardState);

      // Check valid lane move for left position
      if (this.isValidLaneMove(piece, leftPosition, boardState)) {
        if (!leftPiece || !leftPiece.hasMoved) {
          possibleMoves.push({
            position: leftPosition,
            toPiece: leftPiece,
            moveType: MoveType.lane,
            sideEffects: [],
          });
        }
      }

      // Check valid lane move for right position
      if (this.isValidLaneMove(piece, rightPosition, boardState)) {
        if (!rightPiece || !rightPiece.hasMoved) {
          possibleMoves.push({
            position: rightPosition,
            toPiece: rightPiece,
            moveType: MoveType.lane,
            sideEffects: [],
          });
        }
      }
    }

    // Regular movement
    const forwardDirection = piece.isLight ? 1 : -1;

    // Check the two-square forward position first
    const twoSquaresForwardPosition = {
      col: position.col,
      row: position.row + forwardDirection * 2,
    };

    // Check the one-square forward position as fallback
    const oneSquareForwardPosition = {
      col: position.col,
      row: position.row + forwardDirection,
    };

    // First, check the two-square move

    if (this.onBoard(boardState, twoSquaresForwardPosition)) {
      const twoSquaresForwardSquare =
        boardState[twoSquaresForwardPosition.row][
          twoSquaresForwardPosition.col
        ];
      const twoSquaresForwardInfo = this.getSquareInfo(
        twoSquaresForwardSquare,
        piece.isLight
      );

      if (twoSquaresForwardInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          twoSquaresForwardPosition,
          twoSquaresForwardInfo.basicSquareInfo,
          piece,
          allPieceTypes
        );

        if (move) {
          const oneSquareAhead =
            boardState[twoSquaresForwardPosition.row - forwardDirection][
              twoSquaresForwardPosition.col
            ];
          const sideEffects: SideEffect = {
            position: {
              row: twoSquaresForwardPosition.row - forwardDirection,
              col: twoSquaresForwardPosition.col,
            },
            pieces: oneSquareAhead.Pieces,
            effect: Effect.capture,
          };
          move.sideEffects = [sideEffects];
          possibleMoves.push(move);
        }
      }
    } else {
      // If two-square move is off the board, check the one-square move
      if (this.onBoard(boardState, oneSquareForwardPosition)) {
        const oneSquareForwardSquare =
          boardState[oneSquareForwardPosition.row][
            oneSquareForwardPosition.col
          ];
        const oneSquareForwardInfo = this.getSquareInfo(
          oneSquareForwardSquare,
          piece.isLight
        );

        if (oneSquareForwardInfo.basicSquareInfo) {
          const move = this.getMove(
            position,
            oneSquareForwardPosition,
            oneSquareForwardInfo.basicSquareInfo,
            piece,
            allPieceTypes
          );

          if (move) {
            possibleMoves.push(move);
          }
        }
      }
    }

    const backLeftPosition = {
      col: position.col - 1,
      row: position.row - forwardDirection,
    };
    const backRightPosition = {
      col: position.col + 1,
      row: position.row - forwardDirection,
    };

    if (this.onBoard(boardState, backLeftPosition)) {
      const backLeftSquare =
        boardState[backLeftPosition.row][backLeftPosition.col];
      const backLeftInfo = this.getSquareInfo(backLeftSquare, piece.isLight);

      if (backLeftInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          backLeftPosition,
          backLeftInfo.basicSquareInfo,
          piece,
          allPieceTypes
        );

        if (move) {
          possibleMoves.push(move);
        }
      }
    }

    if (this.onBoard(boardState, backRightPosition)) {
      const backRightSquare =
        boardState[backRightPosition.row][backRightPosition.col];
      const backRightInfo = this.getSquareInfo(backRightSquare, piece.isLight);

      if (backRightInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          backRightPosition,
          backRightInfo.basicSquareInfo,
          piece,
          allPieceTypes
        );

        if (move) {
          possibleMoves.push(move);
        }
      }
    }

    // Mega charge
    if (!piece.hasCharged) {
      const forwardPositions = [
        { col: position.col, row: position.row + forwardDirection },
        { col: position.col, row: position.row + forwardDirection * 2 },
        { col: position.col, row: position.row + forwardDirection * 3 },
      ];

      const canMegaCharge = forwardPositions.some((pos) =>
        this.isOccupiedByAnimalPiece(pos, boardState)
      );

      if (canMegaCharge) {
        const megaChargePosition = {
          col: position.col,
          row: position.row + forwardDirection * 3,
        };

        if (this.onBoard(boardState, megaChargePosition)) {
          const megaChargeSquare =
            boardState[megaChargePosition.row][megaChargePosition.col];
          const megaChargeInfo = this.getSquareInfo(
            megaChargeSquare,
            piece.isLight
          );

          if (megaChargeInfo.basicSquareInfo) {
            const move = this.getMove(
              position,
              megaChargePosition,
              megaChargeInfo.basicSquareInfo,
              piece,
              [] // Rhino can capture any piece type
            );

            if (move) {
              possibleMoves.push(move);
            }
          }
        }
      }
    }

    return possibleMoves;
  }

  private isValidLaneMove(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): boolean {
    if (!this.onBoard(boardState, position)) return false;

    const squareInfo = this.getSquareInfo(
      boardState[position.row][position.col],
      piece.isLight
    );

    return (
      squareInfo.basicSquareInfo !== null &&
      !squareInfo.basicSquareInfo.isEmpty &&
      squareInfo.basicSquareInfo.piece?.type !== PieceType.Rhino
    );
  }

  private isOccupiedByAnimalPiece(
    position: Position,
    boardState: ISquare[][]
  ): boolean {
    if (!this.onBoard(boardState, position)) return false;

    const squareInfo = this.getSquareInfo(
      boardState[position.row][position.col],
      true
    );

    return (
      squareInfo.basicSquareInfo !== null &&
      !squareInfo.basicSquareInfo.isEmpty &&
      animalPieceTypes.includes(squareInfo.basicSquareInfo.piece!.type)
    );
  }

  private getPieceAtPosition(
    position: Position,
    boardState: ISquare[][]
  ): IPiece | null {
    if (!this.onBoard(boardState, position)) return null;

    const squareInfo = this.getSquareInfo(
      boardState[position.row][position.col],
      true
    );

    return squareInfo.basicSquareInfo?.piece || null;
  }
}
