import { allPieceTypes } from "../const";
import {
  IPiece,
  PieceMovement,
  PieceType,
  Position,
  IMove,
  ISquare,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class TreeMovement extends BaseMovement implements PieceMovement {
  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    if (piece.isMeadowed) {
      return possibleMoves; // If the tree is meadowed, it cannot complete any moves
    }

    // Regular movement 1: One square forward
    const forwardDirection = piece.isLight ? 1 : -1;
    const forwardPosition = {
      col: position.col,
      row: position.row + forwardDirection,
    };
    if (
      this.onBoard(boardState, forwardPosition) &&
      !this.fungiMoveLimit(forwardPosition, boardState, piece.isLight)
    ) {
      const toSquare = boardState[forwardPosition.row][forwardPosition.col];
      const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          forwardPosition,
          squareInfo.basicSquareInfo,
          piece,
          allPieceTypes
        );

        if (move) {
          possibleMoves.push(move);
        }
      }
    }

    // Regular movement 2: Left or right as far as the player chooses
    const leftPosition = { col: position.col - 1, row: position.row };
    const rightPosition = { col: position.col + 1, row: position.row };

    let currentLeftPosition = leftPosition;
    while (
      this.onBoard(boardState, currentLeftPosition) &&
      !this.fungiMoveLimit(currentLeftPosition, boardState, piece.isLight)
    ) {
      const toSquare =
        boardState[currentLeftPosition.row][currentLeftPosition.col];
      const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          currentLeftPosition,
          squareInfo.basicSquareInfo,
          piece,
          allPieceTypes
        );

        if (move) {
          possibleMoves.push(move);
          if (squareInfo.basicSquareInfo.piece) {
            break;
          }
        } else {
          break;
        }
      }

      currentLeftPosition = {
        col: currentLeftPosition.col - 1,
        row: position.row,
      };
    }

    let currentRightPosition = rightPosition;
    while (
      this.onBoard(boardState, currentRightPosition) &&
      !this.fungiMoveLimit(currentRightPosition, boardState, piece.isLight)
    ) {
      const toSquare =
        boardState[currentRightPosition.row][currentRightPosition.col];
      const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          currentRightPosition,
          squareInfo.basicSquareInfo,
          piece,
          allPieceTypes
        );

        if (move) {
          possibleMoves.push(move);
          if (squareInfo.basicSquareInfo.piece) {
            break;
          }
        } else {
          break;
        }
      }

      currentRightPosition = {
        col: currentRightPosition.col + 1,
        row: position.row,
      };
    }

    return possibleMoves;
  }

  private fungiMoveLimit(
    position: Position,
    boardState: ISquare[][],
    isLight: boolean
  ): boolean {
    for (let row = position.row - 1; row <= position.row + 1; row++) {
      for (let col = position.col - 1; col <= position.col + 1; col++) {
        if (this.onBoard(boardState, { row, col })) {
          const square = boardState[row][col];
          const piece = square.Pieces[0];
          if (
            piece &&
            piece.type === PieceType.Fungi &&
            piece.isLight !== isLight
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
