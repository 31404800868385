import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "./firebaseConfig";

const CollectionName = "games";

// Function to listen for changes in a specific document
export function listenForDocumentChanges(
  documentId: string,
  callback: (data: any | null) => void
) {
  const docRef = doc(db, CollectionName, documentId);
  return onSnapshot(docRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      const data = {
        id: docSnapshot.id,
        ...docSnapshot.data(),
      } as any;
      callback(data);
    } else {
      callback(null);
    }
  });
}

// Function to update or create a document
export async function updateGamesDocument(
  documentId: string,
  data: Partial<any>
): Promise<void> {
  const docRef = doc(db, CollectionName, documentId);
  await setDoc(docRef, data, { merge: true });
}

export async function querySearchingGames(): Promise<
  { id: string; data: any }[]
> {
  const gamesCollection = collection(db, CollectionName);
  const q = query(gamesCollection, where("state", "==", "Searching"));

  try {
    const querySnapshot = await getDocs(q);
    const searchingGames: { id: string; data: any }[] = [];
    querySnapshot.forEach((doc) => {
      searchingGames.push({ id: doc.id, data: { ...doc.data() } });
    });
    return searchingGames;
  } catch (error) {
    console.error("Error querying searching games: ", error);
    throw error;
  }
}

// NOT CURRENTLY USED
// Function to listen for changes in a collection
// export function listenForCollectionChanges(
//   collectionName: string,
//   callback: (data: any[]) => void
// ) {
//   const collectionRef = collection(db, collectionName);
//   return onSnapshot(collectionRef, (querySnapshot) => {
//     const data: any[] = querySnapshot.docs.map(
//       (doc) =>
//         ({
//           id: doc.id,
//           ...doc.data(),
//         } as any)
//     );
//     callback(data);
//   });
// }
