import styles from "./Board.module.css";
import globalStyles from "../App.module.css";
import {
  GameState,
  ISquare,
  IMove,
  Position,
  IPiece,
} from "../services/zodiacEngine/types";
import { memo, useEffect } from "react";
import Square from "./Square";

interface BoardProps {
  gameState: GameState;
  uiState: {
    potentialMoves: IMove[];
    selectedPiece: {
      position: Position;
      piece: IPiece;
    } | null;
  };
  onSquareClick: (
    square: ISquare,
    position: Position,
    move?: IMove,
    pieceClicked?: IPiece
  ) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>, position: Position) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
}

function Board(boardProps: BoardProps) {
  useEffect(() => {
    // This effect will run whenever any prop value changes
    console.log("Props changed:", boardProps);
  }, [boardProps]);

  function isSquarePotentialMove(position: Position): IMove | undefined {
    return boardProps?.uiState?.potentialMoves?.find(
      (x) => x.position.row === position.row && x.position.col === position.col
    );
  }

  function getRowIndex(length: number, index: number): number {
    return length - 1 - index;
  }

  // function isSquareSelectedPiece(
  //   selectedPiece: {
  //     position: Position;
  //     piece: IPiece;
  //   } | null,
  //   thisSquarePosition: Position
  // ): IPiece | null {
  //   if (selectedPiece === null) return null;

  //   if (
  //     thisSquarePosition.col === selectedPiece.position.col &&
  //     thisSquarePosition.row === selectedPiece.position.row
  //   ) {
  //     return selectedPiece.piece;
  //   }
  //   return null;
  // }

  return (
    <div className={styles.board}>
      {[...boardProps.gameState.board].reverse().map((row, rowIndex) => (
        <div key={rowIndex} className={globalStyles.boardrow}>
          {row.map((square, colIndex) => (
            <Square
              key={`${rowIndex}-${colIndex}`}
              square={square}
              position={{
                row: getRowIndex(boardProps.gameState.board.length, rowIndex),
                col: colIndex,
              }}
              onSquareClick={boardProps.onSquareClick}
              onDrop={boardProps.onDrop}
              onDragOver={boardProps.onDragOver}
              move={isSquarePotentialMove({
                row: getRowIndex(boardProps.gameState.board.length, rowIndex),
                col: colIndex,
              })}
              // selectedPiece={
              //   isSquareSelectedPiece(boardProps?.uiState?.selectedPiece,
              //   {
              //     row: getRowIndex(boardProps.gameState.boardState.board.length, rowIndex),
              //     col: colIndex,
              //   }
              // )}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

export default memo(Board);
