import styles from "./Square.module.css";
import { PieceType } from "../services/zodiacEngine/types"; // Adjust the path based on your project structure
import { IPiece } from "../services/zodiacEngine/types";

interface SquareRendererProps {
  pieces: IPiece[];
  handleSelect: (piece: IPiece) => void;
}

export default function SquareRenderer({ pieces, handleSelect }: SquareRendererProps) {
  const getDefaultElement = (): JSX.Element => {
    // Find the tree piece on the square
    const treePiece = pieces.find((piece) => piece.type === PieceType.Tree);

    // Check if there's a tree, and handle the case where there isn't one
    if (!treePiece) {
      // No tree on the square, show the regular piece if there is one
      if (pieces.length === 1) {
  const piece = pieces[0];
  const pieceImagePath = `../icons/${piece.type}-${
    piece.isLight ? "light" : "dark"
  }.png`;

  // Determine the CSS classes
  const pieceClasses = [styles.squareicon];
  if (piece.isMeadowed) {
    pieceClasses.push(styles.meadowPiece); // Add the meadowPiece class
  }

  if (piece.isPoisoned) {
    pieceClasses.push(styles.poisonedPiece); // Add the poisonedPiece class
  }
    if (piece.isZombified) {
    pieceClasses.push(styles.zombifiedPiece); // Add the poisonedPiece class
  }
      if (piece.isEntangled) {
    pieceClasses.push(styles.entangledPiece); // Add the poisonedPiece class
  }
    
  return <img className={pieceClasses.join(' ')} src={pieceImagePath} alt="" />;
}
      return <></>; // Return empty if no pieces are present
    }

    // If there are other pieces besides the tree, trigger floating icons for selection
    if (pieces.length > 1) {
      // Find other pieces (that are not the tree)
      const otherPieces = pieces.filter((piece) => piece.type !== PieceType.Tree);

      // Show floating icons for both tree and housed piece
  return (
    <div className={styles.floatingIcons}>
      {otherPieces.map((piece, index) => {
        const pieceClasses = [styles.floatingIcon];
        if (piece.isMeadowed) {
          pieceClasses.push(styles.meadowedPiece);
        }
        if (piece.isPoisoned) {
          pieceClasses.push(styles.poisonedPiece);
        }
                if (piece.isZombified) {
          pieceClasses.push(styles.zombifiedPiece);
        }
                        if (piece.isEntangled) {
          pieceClasses.push(styles.entangledPiece);
        }
        const pieceImagePath = `../icons/${piece.type}-${
          piece.isLight ? "light" : "dark"
        }.png`;

            return (
              <img
                key={index}
                className={styles.floatingIcon}                
                src={pieceImagePath}
                alt={piece.type}
                onClick={() => handleSelect(piece)} // Use hover and select for multiple pieces
              />
            );
          })}

           <img
        className={`${styles.floatingIcon} ${
          treePiece.isMeadowed ? styles.meadowedPiece : ""
        }`}
        src={`../icons/${treePiece.isLight ? "Tree-light" : "Tree-dark"}.png`}
        alt="Tree"
        onClick={() => handleSelect(treePiece)} // Allow selecting the tree as well
      />
        </div>
      );
    }

    // If there's only the tree on the square, just show the regular tree icon (Tree-light/Tree-dark)
    const treeClasses = [styles.squareicon];
if (treePiece.isMeadowed) {
  treeClasses.push(styles.meadowedPiece);
}
    return   <img
    className={treeClasses.join(" ")}
    src={`../icons/${treePiece.isLight ? "Tree-light" : "Tree-dark"}.png`}
    alt="Tree"
  />
  };

  return <>{getDefaultElement()}</>;
}
