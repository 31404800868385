import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBzGiR-QUX5QMN6qs0_hs-bJIHuICY6Iis",
  authDomain: "test1-chat-app.firebaseapp.com",
  projectId: "test1-chat-app",
  storageBucket: "test1-chat-app.appspot.com",
  messagingSenderId: "342011075381",
  appId: "1:342011075381:web:47e55b38f8e68a6acc0a7c",
  measurementId: "G-KFLT2VR536",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };