import { allPieceTypes } from "../const";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  MoveType,
  ISquare,
} from "../types";
import { BaseMovement } from "./BaseMovement";

const directions: number[][] = [
  [-2, -2], // Top Left
  [-2, 2], // Top Right
  [2, -2], // Bottom Left
  [2, 2], // Bottom Right
];

export class FrogMovement extends BaseMovement implements PieceMovement {
  capturablePieceTypes: PieceType[] = allPieceTypes;

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    if (!piece.hasLilyJumped) {
      this.handleLilyJump(piece, position, boardState, possibleMoves);
    }

    this.handleDiagonalJumps(piece, position, boardState, possibleMoves);
    this.handleWallClimb(piece, position, boardState, possibleMoves);

    return possibleMoves;
  }

  private handleLilyJump(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][],
    possibleMoves: IMove[]
  ) {
    // Determine the starting row based on the piece's color
    const startingRow = piece.isLight ? 7 : 0;

    // Check if the Frog is on its starting row
    if (position.row !== startingRow) {
      return;
    }

    // Determine valid rows based on the piece's color
    const validRows = piece.isLight ? [0, 1, 2] : [7, 6, 5];

    for (let col = 0; col < boardState.length; col++) {
      for (const row of validRows) {
        const potentialPosition = { col, row };
        const targetSquare = boardState[row][col];

        // Check if the target square is within the board and not occupied by an allied piece
        if (
          this.onBoard(boardState, potentialPosition) &&
          (!targetSquare?.Pieces[0] ||
            !this.isAlly(piece, targetSquare?.Pieces[0]))
        ) {
          const isEnemyTree =
            targetSquare?.Pieces[0]?.type === PieceType.Tree &&
            !this.isAlly(piece, targetSquare?.Pieces[0]);
          const isRegularMoveToTree = this.isRegularMove(
            piece,
            position,
            potentialPosition,
            boardState
          );

          const squareInfo = this.getSquareInfo(targetSquare, piece.isLight);
          if (squareInfo.basicSquareInfo) {
            var move = this.getMove(
              position,
              potentialPosition,
              squareInfo.basicSquareInfo,
              piece,
              this.capturablePieceTypes
            );

            possibleMoves.push(move!);

            if (
              isEnemyTree &&
              !isRegularMoveToTree &&
              squareInfo.basicSquareInfo.isEnemyOccupied &&
              squareInfo.basicSquareInfo.piece?.type === PieceType.Tree
            ) {
              possibleMoves.push({
                position: potentialPosition,
                toPiece: targetSquare.Pieces[0] || undefined,
                moveType: MoveType.house,
                sideEffects: [],
              });
            }
          }
        }
      }
    }
  }

  private handleDiagonalJumps(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][],
    possibleMoves: IMove[]
  ) {
    for (const direction of directions) {
      // Check if the regular position (2 squares away diagonally) is within bounds
      const regularPosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      // Check if the backup position (1 square away diagonally) is within bounds
      const backupPosition = {
        col: position.col + Math.floor(direction[0] / 2),
        row: position.row + Math.floor(direction[1] / 2),
      };

      // Check if the regular position is on the board
      const isRegularPositionOnBoard = this.onBoard(
        boardState,
        regularPosition
      );

      if (isRegularPositionOnBoard) {
        const regularSquare =
          boardState[regularPosition.row][regularPosition.col];

        // Check if the regular square is occupied by a friendly piece
        const isRegularSquareOccupiedByFriendly = regularSquare?.Pieces[0]
          ? this.isAlly(piece, regularSquare.Pieces[0]) &&
            regularSquare.Pieces[0].type !== PieceType.Tree
          : false;

        // If the regular square is not occupied by a friendly piece and not blocked by an enemy Rhino in front, add it as a valid move
        if (!isRegularSquareOccupiedByFriendly) {
          const squareInfo = this.getSquareInfo(regularSquare, piece.isLight);
          if (squareInfo.basicSquareInfo) {
            var thisMove = this.getMove(
              position,
              regularPosition,
              squareInfo.basicSquareInfo,
              piece,
              this.capturablePieceTypes
            );

            if (thisMove) {
              possibleMoves.push(thisMove);
            }
          }
        }
      }

      // Check the backup position only if the regular position is blocked or off the board
      if (
        !isRegularPositionOnBoard ||
        (isRegularPositionOnBoard &&
          !possibleMoves.some((move) => move.position === regularPosition))
      ) {
        const isBackupPositionOnBoard = this.onBoard(
          boardState,
          backupPosition
        );

        if (isBackupPositionOnBoard) {
          const backupSquare =
            boardState[backupPosition.row][backupPosition.col];

          // Check if the backup square is occupied by a friendly piece
          const isBackupSquareOccupiedByFriendly =
            backupSquare?.Pieces[0] &&
            this.isAlly(piece, backupSquare.Pieces[0]);

          // If the backup square is not occupied by a friendly piece and not blocked by an enemy Rhino in front, add it as a valid move
          if (!isBackupSquareOccupiedByFriendly) {
            const squareInfo = this.getSquareInfo(backupSquare, piece.isLight);
            if (squareInfo.basicSquareInfo) {
              var thatMove = this.getMove(
                position,
                backupPosition,
                squareInfo.basicSquareInfo,
                piece,
                this.capturablePieceTypes
              );

              if (thatMove) {
                possibleMoves.push(thatMove);
              }
            }
          }
        }
      }
    }
  }

  private handleWallClimb(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][],
    possibleMoves: IMove[]
  ) {
    if (position.col === 0 || position.col === 4) {
      for (let i = -1; i <= 1; i += 2) {
        const potentialPosition = { col: position.col, row: position.row + i };

        if (this.onBoard(boardState, potentialPosition)) {
          const targetSquare =
            boardState[potentialPosition.row][potentialPosition.col];

          const squareInfo = this.getSquareInfo(targetSquare, piece.isLight);
          if (squareInfo.basicSquareInfo) {
            var move = this.getMove(
              position,
              potentialPosition,
              squareInfo.basicSquareInfo,
              piece,
              this.capturablePieceTypes
            );

            if (move) {
              possibleMoves.push(move);
            }
          }
        }
      }
    }
  }

  private isAlly(piece1: IPiece, piece2: IPiece): boolean {
    return piece1.isLight === piece2.isLight;
  }

  private isRegularMove(
    piece: IPiece,
    startPosition: Position,
    endPosition: Position,
    boardState: ISquare[][]
  ): boolean {
    const dx = Math.abs(endPosition.col - startPosition.col);
    const dy = Math.abs(endPosition.row - startPosition.row);

    if (dx === 2 && dy === 2) {
      // Check if the diagonal move is blocked
      const midCol = (startPosition.col + endPosition.col) / 2;
      const midRow = (startPosition.row + endPosition.row) / 2;
      const midSquare = boardState[midRow][midCol];

      if (midSquare.Pieces[0] && !this.isAlly(piece, midSquare.Pieces[0])) {
        // The diagonal jump is blocked by an enemy piece
        return false;
      }

      const targetSquare = boardState[endPosition.row][endPosition.col];
      if (
        targetSquare.Pieces[0] &&
        this.isAlly(piece, targetSquare.Pieces[0])
      ) {
        // The target square is occupied by a friendly piece
        return false;
      }

      return true;
    }

    if (dx === 1 && dy === 1) {
      const targetSquare = boardState[endPosition.row][endPosition.col];
      if (
        targetSquare.Pieces[0] &&
        this.isAlly(piece, targetSquare.Pieces[0])
      ) {
        // The target square is occupied by a friendly piece
        return false;
      }

      return true;
    }

    // Check for wall climb
    if (
      (startPosition.col === 0 || startPosition.col === 4) &&
      dx === 0 &&
      dy === 1
    ) {
      const targetSquare = boardState[endPosition.row][endPosition.col];
      if (
        targetSquare.Pieces[0] &&
        this.isAlly(piece, targetSquare.Pieces[0])
      ) {
        // The target square is occupied by a friendly piece
        return false;
      }

      return true;
    }

    return false;
  }
}
