import { allPieceTypes, directions } from "../const";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  ISquare,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class BearMovement extends BaseMovement implements PieceMovement {
  // All pieces
  capturablePieceTypes: PieceType[] = allPieceTypes;

  getValidMoves(
    piece: IPiece,
    fromPosition: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    for (const direction of directions) {
      const toPosition = {
        col: fromPosition.col + direction[0],
        row: fromPosition.row + direction[1],
      };

      // Short circuit if new position is off board
      if (!this.onBoard(boardState, toPosition)) continue;

      const toSquare = boardState[toPosition.row][toPosition.col];
      const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        var move = this.getMove(
          fromPosition,
          toPosition,
          squareInfo.basicSquareInfo,
          piece,
          this.capturablePieceTypes
        );

        if (move) {
          possibleMoves.push(move);
        }
      }
    }
    return possibleMoves;
  }
}
