import { Square as GameSquare } from "../services/zodiacEngine/Square";
import styles from "./Square.module.css";
import globalStyles from "../App.module.css";
import {
  IMove,
  IPiece,
  ISquare,
  MoveType,
  Position,
} from "../services/zodiacEngine/types";
import SquareRenderer from "./SquareRenderer"; // Import the renderer

interface SquareProps {
  square: GameSquare;
  position: Position;
  move?: IMove;

  onSquareClick: (
    square: ISquare,
    position: Position,
    move?: IMove,
    piece?: IPiece
  ) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>, position: Position) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
}

function Square(squareProps: SquareProps) {
  //const [isSelecting, setIsSelecting] = useState(false);

  const handleSelect = (piece: IPiece) => {
    //setIsSelecting(false); // Reset the selection after a piece is chosen
    squareProps.onSquareClick(
      squareProps.square,
      squareProps.position,
      squareProps.move,
      piece
    );
  };

  const handleClick = () => {
    if (squareProps.square.Pieces.length > 1) {
      //setIsSelecting(true); // If more than one piece, trigger the selection
    } else {
      //setIsSelecting(false);
      squareProps.onSquareClick(
        squareProps.square,
        squareProps.position,
        squareProps.move,
        squareProps.square.Pieces[0]
      );
    }
  };

  const getStyle = (move: IMove): string => {
    var style = globalStyles.square;

    if (!move) return style; // No move, return default style

    // Handle different move types
    switch (move.moveType) {
      case MoveType.trample:
        if (squareProps.square.Pieces.length === 0) {
          // Empty square, apply trampleEmpty (green)
          style += ` ${styles.potentialMoveTrampleEmpty}`;
        } else {
          // Occupied square, apply capture highlight for trample (red)
          style += ` ${styles.potentialMoveTrample} ${styles.potentialMoveCapture}`;
        }
        break;
      case MoveType.capture:
        // Regular capture, apply capture highlight (red)
        style += ` ${styles.potentialMoveCapture}`;
        break;
      case MoveType.regular:
        // Regular move, apply regular highlight (green)
        style += ` ${styles.potentialMoveRegular}`;
        break;
      case MoveType.lane:
        // Lane move, apply lane highlight (grey)
        style += ` ${styles.potentialMoveLane}`;
        break;
      case MoveType.house:
        // House move, apply house highlight
        style += ` ${styles.potentialMoveHouse}`;
        break;
      case MoveType.traverse:
        // Traverse move, apply traverse highlight (yellow)
        style += ` ${styles.potentialMoveTraverse}`;
        break;

      case MoveType.simpleentangle:
      case MoveType.moveentangle:
        // Entangle move, apply traverse highlight (purple)
        style += ` ${styles.potentialMoveEntangle}`;
        break;
      default:
        // No specific move type, return default style
        return style;
    }

    return style;
  };

  return (
    <div
      className={getStyle(squareProps.move!)}
      onClick={handleClick}
      onDrop={(event) => squareProps.onDrop(event, squareProps.position)}
      onDragOver={squareProps.onDragOver}
    >
      <SquareRenderer
        pieces={squareProps.square.Pieces}
        handleSelect={handleSelect}
      />
    </div>
  );
}

export default Square;
