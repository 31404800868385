import { PieceType } from "./types";

export const directions: number[][] = [
  [-1, -1], // Top Left
  [-1, 0], // Left
  [-1, 1], // Top Right
  [0, -1], // Down
  [0, 1], // Up
  [1, -1], // Bottom Left
  [1, 0], // Right
  [1, 1], // Bottom Right
];

export const predatorPieceTypes: PieceType[] = [
  PieceType.Bear,
  PieceType.Wolf,
];

export const largePieceTypes: PieceType[] = [
  PieceType.Bear, 
  PieceType.Rhino,
  PieceType.Tree,
];

export const animalPieceTypes: PieceType[] = [
  PieceType.Bear,
  PieceType.Crane,
  PieceType.Frog,
  PieceType.Goat,
  PieceType.Monkey,
  PieceType.Octopus,
  PieceType.Penguin,
  PieceType.Rhino,
  PieceType.Snake,
  PieceType.Wolf,
]

export const tinyPieceTypes: PieceType[] = [
  PieceType.Virus,
]

export const allPieceTypes: PieceType[] = [
  PieceType.Bear,
  PieceType.Crane,
  PieceType.Frog,
  PieceType.Fungi,
  PieceType.Goat,
  PieceType.Monkey,
  PieceType.Octopus,
  PieceType.Penguin,
  PieceType.Rhino,
  PieceType.Snake,
  PieceType.Tree,
  PieceType.Virus,
  PieceType.Wolf,
];

export const allClimbingPieces: PieceType[] = [
  PieceType.Bear,
  PieceType.Crane,
  PieceType.Frog,
  PieceType.Fungi,
  PieceType.Goat,
  PieceType.Monkey,
  PieceType.Octopus,
  PieceType.Snake,
];
