import { allClimbingPieces } from "../const";
import {
  Position,
  ISquare,
  PieceType,
  IPiece,
  MoveType,
  IMove,
} from "../types";
export interface SquareInfo {
  // todo these might not be null
  basicSquareInfo: BasicSquareInfo | null;
  housedTreeSquareInfo: HousedTreeSquareInfo | null;
  stoodGoatSquareInfo: StoodGoatSquareInfo | null;
  infectedVirusSquareInfo: InfectedVirusSquareInfo | null;
}

export interface HousedTreeSquareInfo {}

export interface StoodGoatSquareInfo {}

export interface InfectedVirusSquareInfo {}

export interface BasicSquareInfo {
  piece: IPiece | null;
  isEmpty: boolean;
  isEnemyOccupied: boolean;
  isAlly: boolean;
  isTree: boolean;
  isZombified: boolean;
}

export class BaseMovement {
  onBoard(board: ISquare[][], position: Position): boolean {
    return !(
      position.col < 0 ||
      position.col + 1 > board[0].length ||
      position.row < 0 ||
      position.row + 1 > board.length
    );
  }

  getSquareInfo(square: ISquare, isLight: boolean): SquareInfo {
    // there are three scenarios where there are multiple pieces on a single square, Virus can combine with either of the others

    // a piece housed in a tree

    // a piece standing on a fainted goat

    // a piece infected by the virus

    return {
      basicSquareInfo: this.getBasicInfo(square, isLight),
      housedTreeSquareInfo: null,
      stoodGoatSquareInfo: null,
      infectedVirusSquareInfo: null,
    };
  }

  getBasicInfo(square: ISquare, isLight: boolean): BasicSquareInfo {
    let isEmpty: boolean = true;
    let isEnemyOccupied: boolean = false;
    let isAlly: boolean = false;
    let isTree: boolean = false;
    let isZombified: boolean = false;

    if (square.Pieces.length === 1) {
      var piece = square.Pieces[0];
      isEmpty = false;
      isEnemyOccupied = isLight !== piece.isLight;
      isAlly = isLight === piece.isLight;
      isTree = piece.type === PieceType.Tree;
      isZombified = piece.isZombified;
    }

    return {
      isEmpty,
      isEnemyOccupied,
      isAlly,
      isTree,
      isZombified,
      piece: square.Pieces[0] ?? null,
    };
  }

  getMove(
    fromPosition: Position,
    toPosition: Position,
    basicSquareInfo: BasicSquareInfo,
    piece: IPiece, // current piece
    capturablePieceTypes: PieceType[]
  ): IMove | undefined {
    const getMove = (moveType: MoveType): IMove => {
      return {
        position: toPosition,
        toPiece: basicSquareInfo.piece,
        moveType: moveType,
        sideEffects: [],
      };
    };

    var isHouseable =
      basicSquareInfo.isAlly &&
      basicSquareInfo.isTree &&
      allClimbingPieces.includes(piece.type);
    var isCraneHouseable =
      piece.type === PieceType.Crane &&
      basicSquareInfo.isEnemyOccupied &&
      basicSquareInfo.isTree;

    var isRhinoTrample = piece.type === PieceType.Rhino;

    var isCapturable =
      basicSquareInfo.isEnemyOccupied &&
      basicSquareInfo.piece?.type !== PieceType.Rhino &&
      capturablePieceTypes.includes(basicSquareInfo.piece!.type);

    var isRhinoCapturable =
      basicSquareInfo.isEnemyOccupied &&
      basicSquareInfo.piece?.type === PieceType.Rhino &&
      capturablePieceTypes.includes(PieceType.Rhino) &&
      (piece.type === PieceType.Bear ||
        (piece.isLight
          ? toPosition.row <= fromPosition.row
          : toPosition.row >= fromPosition.row));

    if (isHouseable || isCraneHouseable) {
      return getMove(MoveType.house);
    }

    if (isRhinoTrample) {
      return getMove(MoveType.trample);
    }

    if (isCapturable || isRhinoCapturable) {
      return getMove(MoveType.capture);
    }

    if (basicSquareInfo.isEmpty) {
      return getMove(MoveType.regular);
    }

    // this square cannot be moved to
    return undefined;
  }

  // OBSELETE - left here so the code compiles
  canMoveToSquare(
    squareInfo: SquareInfo,
    capturablePieceTypes: PieceType[]
  ): boolean {
    if (squareInfo.basicSquareInfo) {
      return (
        squareInfo.basicSquareInfo.isEmpty ||
        (squareInfo.basicSquareInfo.isEnemyOccupied &&
          squareInfo.basicSquareInfo.piece !== null &&
          capturablePieceTypes.includes(
            squareInfo.basicSquareInfo.piece.type
          )) ||
        (squareInfo.basicSquareInfo.isAlly &&
          squareInfo.basicSquareInfo.isTree) ||
        (squareInfo.basicSquareInfo.isAlly &&
          squareInfo.basicSquareInfo.isZombified)
      );
    }

    return false;
  }

  // OBSELETE - left here so the code compiles
  getMoveType(squareInfo: SquareInfo, piece: IPiece): MoveType {
    let moveType = MoveType.regular;

    if (squareInfo.basicSquareInfo) {
      if (
        squareInfo.basicSquareInfo.isAlly &&
        squareInfo.basicSquareInfo.isTree
      ) {
        moveType = MoveType.house;
      } else if (
        squareInfo.basicSquareInfo.isEnemyOccupied ||
        (squareInfo.basicSquareInfo.isAlly &&
          squareInfo.basicSquareInfo.isZombified)
      ) {
        moveType = MoveType.capture;
      } else if (
        piece.type === PieceType.Crane &&
        squareInfo.basicSquareInfo.isEnemyOccupied &&
        squareInfo.basicSquareInfo.isTree
      ) {
        moveType = MoveType.house;
      }
    }

    return moveType;
  }
}
