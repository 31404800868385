import globalStyles from "./App.module.css";
import BoardContainer from "./components/BoardContainer";

// import React, { useState, useEffect, ChangeEvent } from "react";
// import { io, Socket } from "socket.io-client";

//const socket: Socket = io("http://localhost:3001");

function App() {
  // const [chatHistory, setChatHistory] = useState<string[]>([]);
  // const [inputText, setInputText] = useState<string>("");

  // useEffect(() => {
  //   // Listen for game state updates from the server
  //   socket.on("chat", (chatHistory: string[]) => {
  //     setChatHistory(chatHistory);
  //   });

  //   return () => {
  //     socket.off("chat");
  //   };
  // }, []);

  // const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   setInputText(e.target.value);
  // };

  // const handleMessage = (message: string) => {
  //   // Send move to the server
  //   socket.emit("message", message);
  // };

  // const handleSubmit = () => {
  //   handleMessage(inputText);
  //   setInputText("");
  // };

  // if (!chatHistory) return <div>Loading...</div>;

  return (
    <div>
      <div className={globalStyles.app}>
        <BoardContainer />
      </div>
      {/* <div>
        <h1>Server socket.io chat</h1>
        {chatHistory.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
        <input
          type="text"
          value={inputText}
          onChange={handleInputChange}
          placeholder="Type something..."
          className="flex-grow"
        />
        <button onClick={handleSubmit}>Submit</button>
      </div> */}
    </div>
  );
}

export default App;
