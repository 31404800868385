import { PieceMovementFactory } from "./PieceMovement/PieceMovementFactory";
import {
  Direction,
  IPiece,
  PieceMovement,
  PieceType,
  Position,
  IMove,
  ISquare,
} from "./types";

export class Piece implements IPiece {
  // not on the interface
  movementStrategy: PieceMovement;

  constructor(
    type: PieceType,
    isLight: boolean,
    direction: Direction | null = null
  ) {
    this.type = type;
    this.isLight = isLight;
    this.isKing = false;
    this.isPoisoned = false;
    this.isZombified = false;
    this.isFainted = false;
    this.isEntangled = false;
    this.isShadowed = false;
    this.isMeadowed = false;
    this.isRiding = false;
    this.isHousing = false;
    this.isLone = false;
    this.isDormant = false;
    this.hasCharged = false;
    this.hasMoved = false;
    this.hasShed = false;
    this.hasLilyJumped = false;
    this.direction = direction;

    // todo the new piece movement factory here isn't super ideal, but it will do for now
    this.movementStrategy = new PieceMovementFactory().GetPieceMovement(
      this.type
    );
  }

  type: PieceType;
  isLight: boolean;
  isKing: boolean;
  isPoisoned: boolean;
  isZombified: boolean;
  isFainted: boolean;
  isEntangled: boolean;
  isShadowed: boolean;
  direction: Direction | null;
  hasCharged: boolean;
  hasMoved: boolean;
  hasShed: boolean;
  isMeadowed: boolean;
  isRiding: boolean;
  isHousing: boolean;
  isLone: boolean;
  isDormant: boolean;
  hasLilyJumped: boolean;

  getValidMovesForPiece(board: ISquare[][], position: Position): IMove[] {
    return this.movementStrategy.getValidMoves(this, position, board);
  }
}
