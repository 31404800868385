import { allPieceTypes, directions } from "../const";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  ISquare,
  IMove,
  MoveType,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class MonkeyMovement extends BaseMovement implements PieceMovement {
  private readonly capturablePieceTypes: PieceType[] = allPieceTypes;

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    // Regular movement and capture
    const regularMoves = this.getValidRegularMoves(piece, position, boardState);
    possibleMoves.push(...regularMoves);

    // Jumping movement
    const jumpMoves = this.getValidJumpMoves(piece, position, boardState);
    possibleMoves.push(...jumpMoves);

    // Interaction with Tree piece
    const treeMoves = this.getValidTreeMoves(piece, position, boardState);
    possibleMoves.push(...treeMoves);

    return possibleMoves;
  }

  private getValidRegularMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    for (const direction of directions) {
      const destinationPosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      if (!this.onBoard(boardState, destinationPosition)) {
        continue;
      }

      const destinationSquare =
        boardState[destinationPosition.row][destinationPosition.col];
      const squareInfo = this.getSquareInfo(destinationSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          destinationPosition,
          squareInfo.basicSquareInfo,
          piece,
          this.capturablePieceTypes
        );

        if (move) {
          possibleMoves.push(move);
        }
      }
    }

    return possibleMoves;
  }

  private getValidJumpMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    // Validate single jump moves
    possibleMoves.push(
      ...this.getValidSingleJumpMoves(piece, position, boardState)
    );

    // Validate double jump moves
    possibleMoves.push(
      ...this.getValidDoubleJumpMoves(piece, position, boardState)
    );

    // Validate triple jump moves
    possibleMoves.push(
      ...this.getValidTripleJumpMoves(piece, position, boardState)
    );

    return possibleMoves;
  }

  private getValidSingleJumpMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    for (const direction of directions) {
      const piecePosition = {
        col: position.col + direction[0] * 1,
        row: position.row + direction[1] * 1,
      };
      const endPosition = {
        col: position.col + direction[0] * 2,
        row: position.row + direction[1] * 2,
      };

      if (
        this.onBoard(boardState, piecePosition) &&
        this.onBoard(boardState, endPosition) &&
        this.isValidJumpPiece(
          boardState[piecePosition.row][piecePosition.col],
          piece.isLight
        )
      ) {
        const endSquare = boardState[endPosition.row][endPosition.col];
        const endSquareInfo = this.getSquareInfo(endSquare, piece.isLight);

        if (endSquareInfo.basicSquareInfo) {
          const move = this.getMove(
            position,
            endPosition,
            endSquareInfo.basicSquareInfo,
            piece,
            this.capturablePieceTypes
          );

          if (move) {
            possibleMoves.push(move);
          }
        }
      }
    }

    return possibleMoves;
  }

  private getValidDoubleJumpMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    for (const direction of directions) {
      const piecePosition1 = {
        col: position.col + direction[0] * 1,
        row: position.row + direction[1] * 1,
      };
      const emptyPosition = {
        col: position.col + direction[0] * 2,
        row: position.row + direction[1] * 2,
      };
      const piecePosition2 = {
        col: position.col + direction[0] * 3,
        row: position.row + direction[1] * 3,
      };
      const endPosition = {
        col: position.col + direction[0] * 4,
        row: position.row + direction[1] * 4,
      };

      if (
        this.onBoard(boardState, piecePosition1) &&
        this.onBoard(boardState, emptyPosition) &&
        this.onBoard(boardState, piecePosition2) &&
        this.onBoard(boardState, endPosition) &&
        this.isValidJumpPiece(
          boardState[piecePosition1.row][piecePosition1.col],
          piece.isLight
        ) &&
        this.isEmptySquare(boardState[emptyPosition.row][emptyPosition.col]) &&
        this.isValidJumpPiece(
          boardState[piecePosition2.row][piecePosition2.col],
          piece.isLight
        )
      ) {
        const endSquare = boardState[endPosition.row][endPosition.col];
        const endSquareInfo = this.getSquareInfo(endSquare, piece.isLight);

        if (endSquareInfo.basicSquareInfo) {
          const move = this.getMove(
            position,
            endPosition,
            endSquareInfo.basicSquareInfo,
            piece,
            this.capturablePieceTypes
          );

          if (move) {
            possibleMoves.push(move);
          }
        }
      }
    }

    return possibleMoves;
  }

  private getValidTripleJumpMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    for (const direction of directions) {
      const piecePosition1 = {
        col: position.col + direction[0] * 1,
        row: position.row + direction[1] * 1,
      };
      const emptyPosition1 = {
        col: position.col + direction[0] * 2,
        row: position.row + direction[1] * 2,
      };
      const piecePosition2 = {
        col: position.col + direction[0] * 3,
        row: position.row + direction[1] * 3,
      };
      const emptyPosition2 = {
        col: position.col + direction[0] * 4,
        row: position.row + direction[1] * 4,
      };
      const piecePosition3 = {
        col: position.col + direction[0] * 5,
        row: position.row + direction[1] * 5,
      };
      const endPosition = {
        col: position.col + direction[0] * 6,
        row: position.row + direction[1] * 6,
      };

      if (
        this.onBoard(boardState, piecePosition1) &&
        this.onBoard(boardState, emptyPosition1) &&
        this.onBoard(boardState, piecePosition2) &&
        this.onBoard(boardState, emptyPosition2) &&
        this.onBoard(boardState, piecePosition3) &&
        this.onBoard(boardState, endPosition) &&
        this.isValidJumpPiece(
          boardState[piecePosition1.row][piecePosition1.col],
          piece.isLight
        ) &&
        this.isEmptySquare(
          boardState[emptyPosition1.row][emptyPosition1.col]
        ) &&
        this.isValidJumpPiece(
          boardState[piecePosition2.row][piecePosition2.col],
          piece.isLight
        ) &&
        this.isEmptySquare(
          boardState[emptyPosition2.row][emptyPosition2.col]
        ) &&
        this.isValidJumpPiece(
          boardState[piecePosition3.row][piecePosition3.col],
          piece.isLight
        )
      ) {
        const endSquare = boardState[endPosition.row][endPosition.col];
        const endSquareInfo = this.getSquareInfo(endSquare, piece.isLight);

        if (endSquareInfo.basicSquareInfo) {
          const move = this.getMove(
            position,
            endPosition,
            endSquareInfo.basicSquareInfo,
            piece,
            this.capturablePieceTypes
          );

          if (move) {
            possibleMoves.push(move);
          }
        }
      }
    }

    return possibleMoves;
  }

  private isValidJumpPiece(square: ISquare, isLight: boolean): boolean {
    const squareInfo = this.getSquareInfo(square, isLight);

    if (squareInfo.basicSquareInfo?.piece) {
      const { piece } = squareInfo.basicSquareInfo;
      return (
        piece.type !== PieceType.Goat &&
        piece.type !== PieceType.Virus &&
        !this.isFaintedGoat(piece)
      );
    }

    return false;
  }

  private isFaintedGoat(piece: IPiece | null): boolean {
    return piece?.type === PieceType.Goat && piece?.isFainted === true;
  }

  private isEmptySquare(square: ISquare): boolean {
    return square.Pieces.length === 0;
  }

  private getValidTreeMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];
    const currentSquare = boardState[position.row][position.col];
    const currentSquareInfo = this.getSquareInfo(currentSquare, piece.isLight);

    if (!currentSquareInfo.housedTreeSquareInfo) {
      for (const direction of directions) {
        const destinationPosition = {
          col: position.col + direction[0],
          row: position.row + direction[1],
        };
        if (!this.onBoard(boardState, destinationPosition)) continue;
        const destinationSquare =
          boardState[destinationPosition.row]?.[destinationPosition.col];
        const destinationSquareInfo = this.getSquareInfo(
          destinationSquare,
          piece.isLight
        );

        if (
          destinationSquareInfo.basicSquareInfo?.piece?.type ===
            PieceType.Tree &&
          !destinationSquareInfo.housedTreeSquareInfo
        ) {
          possibleMoves.push({
            position: destinationPosition,
            toPiece: destinationSquare.Pieces[0] || undefined,
            moveType: MoveType.house,
            sideEffects: [],
          });
        }
      }
    }
    return possibleMoves;
  }

  private getJumpPath(
    start: Position,
    end: Position,
    boardState: ISquare[][]
  ): ISquare[] {
    const path: ISquare[] = [];
    const dx = Math.sign(end.col - start.col);
    const dy = Math.sign(end.row - start.row);
    let { col, row } = start;

    while (col !== end.col || row !== end.row) {
      col += dx;
      row += dy;
      if (
        col < 0 ||
        col >= boardState[0].length ||
        row < 0 ||
        row >= boardState.length
      )
        break;
      path.push(boardState[row][col]);
    }
    return path;
  }
}
