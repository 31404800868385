import { allPieceTypes, directions } from "../const";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  MoveType,
  ISquare,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class GoatMovement extends BaseMovement implements PieceMovement {
  capturablePieceTypes: PieceType[] = allPieceTypes.filter(
    (pieceType) => pieceType !== PieceType.Bear && pieceType !== PieceType.Wolf
  );

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    if (piece.isFainted) {
      return this.getValidFaintedMoves(piece, position, boardState);
    }

    const regularMoves = this.getValidRegularMoves(piece, position, boardState);
    const traverseMoves = this.getValidTraverseMoves(
      piece,
      position,
      boardState
    );
    const faintMove = this.getValidFaintMove(piece, position, boardState);

    console.log("Regular Moves:", regularMoves);
    console.log("Traverse Moves:", traverseMoves);
    console.log("Faint Move:", faintMove);

    // Filter out traverse moves that fall on the same squares as regular moves
    const filteredTraverseMoves = traverseMoves.filter(
      (traverseMove) =>
        !regularMoves.some(
          (regularMove) =>
            regularMove.position.col === traverseMove.position.col &&
            regularMove.position.row === traverseMove.position.row
        )
    );

    // Concatenate the arrays in the desired order
    return [...regularMoves, ...filteredTraverseMoves, ...faintMove];
  }

  private getValidRegularMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    for (const direction of directions) {
      const newPosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      if (!this.onBoard(boardState, newPosition)) {
        continue;
      }

      const newSquare = boardState[newPosition.row][newPosition.col];
      const squareInfo = this.getSquareInfo(newSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          newPosition,
          squareInfo.basicSquareInfo,
          piece,
          this.capturablePieceTypes
        );

        if (move) {
          possibleMoves.push(move);
        }
      }
    }

    return possibleMoves;
  }

  private getValidTraverseMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const validMoves: IMove[] = [];
    const visited: Set<string> = new Set();

    const isTraversablePiece = (p: IPiece | null): boolean => {
      return (
        p !== null &&
        !(p.type === PieceType.Goat && p.isFainted) &&
        p.type !== PieceType.Virus
      );
    };

    const traverseHelper = (currentPosition: Position): void => {
      const key = `${currentPosition.col},${currentPosition.row}`;
      if (visited.has(key)) return;
      visited.add(key);

      for (const direction of directions) {
        const newPosition = {
          col: currentPosition.col + direction[0],
          row: currentPosition.row + direction[1],
        };
        if (!this.onBoard(boardState, newPosition)) {
          continue;
        }

        const newSquare = boardState[newPosition.row][newPosition.col];
        const squareInfo = this.getSquareInfo(newSquare, piece.isLight);

        if (squareInfo.basicSquareInfo) {
          if (squareInfo.basicSquareInfo.isEmpty) {
            validMoves.push({
              position: newPosition,
              toPiece: newSquare.Pieces[0] || null,
              moveType: MoveType.traverse,
              sideEffects: [],
            });
          } else if (
            squareInfo.basicSquareInfo.piece?.type === PieceType.Tree
          ) {
            // Allow traversing onto a Tree square and continue traversing from it
            validMoves.push({
              position: newPosition,
              toPiece: squareInfo.basicSquareInfo.piece,
              moveType: MoveType.house,
              sideEffects: [],
            });
            traverseHelper(newPosition);
          } else if (isTraversablePiece(squareInfo.basicSquareInfo.piece)) {
            traverseHelper(newPosition);
          }
        }
      }
    };

    traverseHelper(position);

    return validMoves;
  }

  private getValidFaintMove(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const currentSquare = boardState[position.row][position.col];
    const squareInfo = this.getSquareInfo(currentSquare, piece.isLight);

    console.log("Inside getValidFaintMove");
    console.log("Piece:", piece);
    console.log("Position:", position);
    console.log("Square Info:", squareInfo);

    if (piece.isFainted) {
      console.log("Goat is already fainted, returning empty array");
      return []; // Goat cannot faint if already fainted
    }

    const faintMove: IMove = {
      position: position,
      toPiece: null,
      moveType: MoveType.faint,
      sideEffects: [],
    };

    console.log("Faint Move:", faintMove);

    return [faintMove]; // Goat can faint in its current position
  }

  private getValidFaintedMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    //const currentSquare = boardState[position.row][position.col];
    //const squareInfo = this.getSquareInfo(currentSquare, piece.isLight);

    // Check if there is another piece on top of the fainted Goat
    //if (squareInfo.stoodGoatSquareInfo?.piece !== piece) {
    //  return []; // Goat cannot move when another piece is on top of it
    //}

    // If no piece is on top of the fainted Goat, allow it to make a regular move or traverse move
    const regularMoves = this.getValidRegularMoves(piece, position, boardState);
    const traverseMoves = this.getValidTraverseMoves(
      piece,
      position,
      boardState
    );

    return [...regularMoves, ...traverseMoves];
  }
}
