import { PieceMovement, PieceType } from "../types";
import { BearMovement } from "./BearMovement";
import { CraneMovement } from "./CraneMovement";
import { FrogMovement } from "./FrogMovement";
import { FungiMovement } from "./FungiMovement";
import { GoatMovement } from "./GoatMovement";
import { MonkeyMovement } from "./MonkeyMovement";
import { OctopusMovement } from "./OctopusMovement";
import { PenguinMovement } from "./PenguinMovement";
import { RhinoMovement } from "./RhinoMovement";
import { SnakeMovement } from "./SnakeMovement";
import { TreeMovement } from "./TreeMovement";
 import { VirusMovement } from "./VirusMovement";
import { WolfMovement } from "./WolfMovement";

export class PieceMovementFactory {
  GetPieceMovement(pieceType: PieceType): PieceMovement {
    switch (pieceType) {
      case PieceType.Bear:
        return new BearMovement();
      case PieceType.Crane:
        return new CraneMovement();
      case PieceType.Frog:
        return new FrogMovement();
      case PieceType.Fungi:
        return new FungiMovement();
      case PieceType.Goat:
        return new GoatMovement();
      case PieceType.Monkey:
        return new MonkeyMovement();
      case PieceType.Octopus:
        return new OctopusMovement();
       case PieceType.Penguin:
         return new PenguinMovement();
       case PieceType.Rhino:
         return new RhinoMovement();
      case PieceType.Snake:
        return new SnakeMovement();
      case PieceType.Tree:
        return new TreeMovement();
        case PieceType.Virus:
          return new VirusMovement();
      case PieceType.Wolf:
        return new WolfMovement();
      default:
        throw new Error(
          `cannot get movement, ${pieceType} is an invalid piece`
        );
    }
  }
}
