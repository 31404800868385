import { allPieceTypes, animalPieceTypes } from "../const";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  MoveType,
  ISquare,
} from "../types";
import { BaseMovement } from "./BaseMovement";

const directions: number[][] = [
  [0, 1], // Up
  [0, -1], // Down
  [1, 0], // Right
  [-1, 0], // Left
];

export class SnakeMovement extends BaseMovement implements PieceMovement {
  capturablePieceTypes: PieceType[] = allPieceTypes;

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    // Regular movement
    for (const direction of directions) {
      const toPosition = {
        col: position.col + direction[0] * 2,
        row: position.row + direction[1] * 2,
      };

      if (this.onBoard(boardState, toPosition)) {
        const toSquare = boardState[toPosition.row][toPosition.col];
        const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

        if (squareInfo.basicSquareInfo) {
          const move = this.getMove(
            position,
            toPosition,
            squareInfo.basicSquareInfo,
            piece,
            this.capturablePieceTypes
          );

          if (move) {
            possibleMoves.push(move);
            continue;
          }
        }
      }

      // Check backup square
      const backupPosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      if (this.onBoard(boardState, backupPosition)) {
        const backupSquare = boardState[backupPosition.row][backupPosition.col];
        const backupSquareInfo = this.getSquareInfo(
          backupSquare,
          piece.isLight
        );

        if (backupSquareInfo.basicSquareInfo) {
          const move = this.getMove(
            position,
            backupPosition,
            backupSquareInfo.basicSquareInfo,
            piece,
            this.capturablePieceTypes
          );

          if (move) {
            possibleMoves.push(move);
          }
        }
      }
    }

    // Snake strike
    const forwardDirection = piece.isLight ? 1 : -1;
    const strikePosition = {
      col: position.col,
      row: position.row + forwardDirection * 3,
    };

    if (this.isValidStrike(piece, position, strikePosition, boardState)) {
      const toSquare = boardState[strikePosition.row][strikePosition.col];
      const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          strikePosition,
          squareInfo.basicSquareInfo,
          piece,
          this.capturablePieceTypes
        );

        if (move && move.moveType === MoveType.capture) {
          possibleMoves.push(move);
        }
      }
    }

    return possibleMoves;
  }

  private isValidStrike(
    piece: IPiece,
    startPosition: Position,
    endPosition: Position,
    boardState: ISquare[][]
  ): boolean {
    if (!this.onBoard(boardState, endPosition)) return false;
    const targetSquare = boardState[endPosition.row][endPosition.col];
    const targetPiece = targetSquare.Pieces[0];
    if (!targetPiece || targetPiece.isLight === piece.isLight) return false;
    if (!animalPieceTypes.includes(targetPiece.type)) return false;

    const forwardDirection = piece.isLight ? 1 : -1;
    for (let i = 1; i < 3; i++) {
      const intermediatePosition = {
        col: startPosition.col,
        row: startPosition.row + forwardDirection * i,
      };
      if (!this.onBoard(boardState, intermediatePosition)) continue;

      const intermediateSquare =
        boardState[intermediatePosition.row][intermediatePosition.col];
      const intermediatePiece = intermediateSquare.Pieces[0];

      if (
        intermediatePiece &&
        intermediatePiece.type !== PieceType.Goat &&
        intermediatePiece.type !== PieceType.Virus
      ) {
        return false;
      }
    }

    return true;
  }
}
