import {
  listenForDocumentChanges,
  querySearchingGames,
  updateGamesDocument,
} from "../firebase/firebase";
import {
  convertFromFirebaseState,
  convertToFirebaseState,
} from "../firebase/firebaseConverter";
import { Piece } from "./Piece";
import { Square } from "./Square";
import { animalPieceTypes } from "./const";
import {
  GameState,
  IMove,
  IPiece,
  Position,
  ISquare,
  PieceType,
  MoveType,
  State,
  Mode,
  SideEffect,
  Effect,
} from "./types";

type GameStateListener = () => void;

export default class ZodiacEngine {
  private gameStateListeners: Set<GameStateListener> = new Set();
  private unsubscribeDocument: (() => void) | null = null;

  gameState: GameState;
  gameId?: string;

  constructor() {
    this.gameId = undefined;
    this.gameState = {
      mode: Mode.Dev,
      state: State.PreGame,
      board: this.initializeBoard(),
    };
  }

  // Use this to switch after the engine is already instantiated
  switchToLiveMove() {
    this.emptyBoard();
    this.setupLiveMode();
    this.notifyLocalListeners();
  }

  switchToDevMove() {
    if (this.unsubscribeDocument) {
      this.unsubscribeDocument();
      this.unsubscribeDocument = null;
    }
    this.emptyBoard();
    this.gameState.mode = Mode.Dev;
    this.gameState.state = State.PreGame;
    this.gameId = undefined;

    // todo  set because the drag and drop is bound to this
    this.notifyLocalListeners();
  }

  async setupLiveMode() {
    this.gameState.mode = Mode.Live;

    // query firebase for open games
    // if there is some
    // join the first
    // else create a new game in searching state
    var searchingGames = await querySearchingGames();

    if (searchingGames.length > 0) {
      this.gameId = searchingGames[0].id;
      this.gameState = convertFromFirebaseState(searchingGames[0].data);
      this.gameState.state = State.PreGame;
    } else {
      //TODO remove: this makes testing remote games easier
      this.placePiece(new Piece(PieceType.Crane, true), { col: 0, row: 7 });
      this.placePiece(new Piece(PieceType.Tree, true), { col: 0, row: 6 });
      this.placePiece(new Piece(PieceType.Bear, false), { col: 1, row: 6 });
      this.gameId = crypto.randomUUID().toString();
      this.gameState.state = State.Searching;
    }

    this.unsubscribeDocument = listenForDocumentChanges(
      this.gameId!,
      (data: any | null) => {
        if (data) {
          console.log("Document changed, new data:", data);
          this.gameState = convertFromFirebaseState(data);
          this.notifyLocalListeners();
        } else {
          console.log("Document does not exist");
        }
      }
    );
    this.sendUpdateToRemote();
    this.notifyLocalListeners();
  }

  sendUpdateToRemote() {
    if (this.gameState.mode !== Mode.Live) return;
    if (!this.gameId) throw Error("Game id not set");

    const firebaseState = convertToFirebaseState(this.gameState);
    updateGamesDocument(this.gameId!, firebaseState)
      .then(() => {
        console.log("Document updated successfully");
      })
      .catch((error: any) => {
        console.error("Error updating document:", error);
      });
  }

  subscribeToGameStateChanges(listener: GameStateListener): void {
    // console.log(
    //   "Subscribing listener. Current listeners:",
    //   this.gameStateListeners.size
    // );

    this.gameStateListeners.add(listener);

    //console.log("Listeners after subscription:", this.gameStateListeners.size);
  }

  unsubscribeToGameStateChanges(listener: GameStateListener): void {
    // console.log(
    //   "Unsubscribing listener. Current listeners:",
    //   this.gameStateListeners.size
    // );

    this.gameStateListeners.delete(listener);

    // console.log(
    //   "Listeners after unsubscription:",
    //   this.gameStateListeners.size
    // );
  }

  // This method changes the local state
  notifyLocalListeners(): void {
    // console.log(
    //   "Notifying",
    //   this.gameStateListeners.size,
    //   "listeners of game state change"
    // );

    this.gameStateListeners.forEach((listener) => listener());
  }

  ungracefulTermination() {
    if (this.gameId) {
      this.gameState.state = State.Terminated;
      console.log(this.gameId);
      console.log("Ungraceful termination");
    }
  }

  /**
   * Board Representation:
   * (0,0) is the bottom-left corner
   * x-axis (columns) increases from left to right (0 to 4)
   * y-axis (rows) increases from bottom to top (0 to 7)
   *
   * Example:
   * 7 | 0 0 0 0 0
   * 6 | 0 0 0 0 0
   * 5 | 0 0 0 0 0
   * 4 | 0 0 0 0 0
   * 3 | 0 0 0 0 0
   * 2 | 0 0 0 0 0
   * 1 | 0 0 0 0 0
   * 0 | 0 0 0 0 0
   *     0 1 2 3 4
   */

  initializeBoard(): ISquare[][] {
    const rows = 8;
    const columns = 5;
    const board: ISquare[][] = [];

    for (let row = 0; row < rows; row++) {
      const reversedRow = rows - 1 - row;
      board[reversedRow] = new Array(columns)
        .fill(null)
        .map(() => this.getEmptySquare());
    }

    return board;
  }

  getGameState(): GameState {
    return { ...this.gameState };
  }

  getGameId(): String {
    return this.gameId === undefined ? "No Game Id" : this.gameId!;
  }

  nextGameState() {
    switch (this.gameState.state) {
      case State.PreGame:
        this.gameState.state = State.Game;
        break;
      case State.Game:
        this.gameState.state = State.PostGame;
        break;

      default:
        break;
    }
  }

  getBoardState(): ISquare[][] {
    return this.gameState.board;
  }

  getPiecesAt(position: Position): IPiece[] {
    return this.gameState.board[position.row][position.col].Pieces;
  }

  makeMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece | null,
    moveType: MoveType,
    sideEffects: SideEffect[]
  ): boolean {
    let validMoves = piece.getValidMovesForPiece(
      this.gameState.board,
      fromPosition
    );

    if (
      validMoves.some(
        (x) =>
          x.position.col === toPosition.col && x.position.row === toPosition.row
      )
    ) {
      piece.hasMoved = true;
      switch (moveType) {
        case MoveType.regular:
          this.regularMove(fromPosition, toPosition, piece);
          break;
        case MoveType.traverse:
          this.traverseMove(fromPosition, toPosition, piece);
          break;
        case MoveType.capture:
          this.captureMove(
            fromPosition,
            toPosition,
            piece,
            toPiece!,
            sideEffects
          );
          break;
        case MoveType.house:
          this.houseMove(fromPosition, toPosition, piece, toPiece!);
          break;
        case MoveType.trample:
          this.trampleMove(
            fromPosition,
            toPosition,
            piece,
            toPiece!,
            sideEffects
          );
          break;
        case MoveType.simpleentangle:
          this.simpleEntangleMove(
            fromPosition,
            toPosition,
            piece,
            toPiece!,
            sideEffects
          );
          break;
        case MoveType.moveentangle:
          this.moveEntangleMove(
            fromPosition,
            toPosition,
            piece,
            toPiece!,
            sideEffects
          );
          break;
        default:
          throw new Error(`Unhandled move type: ${moveType}`);
      }
      this.sendUpdateToRemote();

      this.handleSideEffects(
        fromPosition,
        toPosition,
        piece,
        toPiece,
        moveType,
        sideEffects
      );

      return true;
    } else {
      return false;
    }
  }

  handleSideEffects(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece, // Attacking or moving piece
    toPiece: IPiece | null, // Defending piece (captured piece)
    moveType: MoveType,
    sideEffects: SideEffect[]
  ): void {
    // Process captures and apply side effects
    sideEffects.forEach((sideEffect) => {
      if (sideEffect.effect === Effect.capture) {
        sideEffect.pieces.forEach((capturedPiece) => {
          // Remove the captured piece from the board
          this.removePiece(sideEffect.position, capturedPiece);

          // Apply poison logic
          if (
            capturedPiece.type === PieceType.Frog &&
            animalPieceTypes.includes(piece.type)
          ) {
            piece.isPoisoned = true;
          }

          // Apply zombie logic
          if (
            capturedPiece.type === PieceType.Fungi &&
            animalPieceTypes.includes(piece.type)
          ) {
            piece.isZombified = true;
          }
        });
      }

      // Meadow logic
      if (sideEffect.effect === Effect.meadow) {
        sideEffect.pieces.forEach((adjacentPiece) => {
          if (
            adjacentPiece.type === PieceType.Tree &&
            adjacentPiece.isLight !== piece.isLight // Opposite color Tree
          ) {
            adjacentPiece.isMeadowed = true;
          }
        });
      }
    });
  }

  private traverseMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece
  ): void {
    // Implement the logic specific to a traverse move
    // For example, moving the piece and handling any special conditions
    this.removePiece(fromPosition, piece);
    this.gameState.board[toPosition.row][toPosition.col].Pieces.push(piece);
  }

  private simpleEntangleMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece,
    sideEffects: SideEffect[]
  ): void {
    //Apply entangle logic
    if (
      piece.type === PieceType.Octopus &&
      toPiece.isLight !== piece.isLight &&
      animalPieceTypes.includes(toPiece.type)
    ) {
      toPiece.isEntangled = true;
    }
  }

  private moveEntangleMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece,
    sideEffects: SideEffect[]
  ): void {
    //Apply entangle logic
    console.log(
      "HEY topiece",
      toPiece,
      "origin",
      fromPosition,
      "move position",
      sideEffects[0].position,
      "current piece",
      piece
    );
    toPiece.isEntangled = true;
    this.regularMove(fromPosition, sideEffects[0].position, piece);
  }

  regularMove(fromPosition: Position, toPosition: Position, piece: IPiece) {
    // remove piece from square at fromPosition
    this.removePiece(fromPosition, piece);

    // add piece at to position
    this.gameState.board[toPosition.row][toPosition.col].Pieces.push(piece);
  }

  removePiece(position: Position, piece: IPiece) {
    this.gameState.board[position.row][position.col].Pieces =
      this.gameState.board[position.row][position.col].Pieces.filter(
        (x) => x !== piece
      );
  }

  private laneMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece
  ): void {
    // Move the current piece from the 'fromPosition' to the 'toPosition'
    this.regularMove(fromPosition, toPosition, piece);

    // Move the piece in the 'toPosition' to the 'fromPosition'
    this.regularMove(toPosition, fromPosition, toPiece);
  }

  private trampleMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece | null,
    sideEffects: SideEffect[]
  ): void {
    const rowDiff = toPosition.row - fromPosition.row;
    const colDiff = toPosition.col - fromPosition.col;
    const rowStep = rowDiff === 0 ? 0 : rowDiff > 0 ? 1 : -1;
    const colStep = colDiff === 0 ? 0 : colDiff > 0 ? 1 : -1;

    let currentRow = fromPosition.row + rowStep;
    let currentCol = fromPosition.col + colStep;

    // Traverse the path to the destination
    while (currentRow !== toPosition.row || currentCol !== toPosition.col) {
      const currentPosition = { row: currentRow, col: currentCol };
      const square = this.gameState.board[currentRow][currentCol];
      const piecesAtPosition = square.Pieces.slice(); // Copy to avoid mutation issues

      if (piecesAtPosition.length > 0) {
        // Collect capture side effects
        sideEffects.push({
          effect: Effect.capture,
          pieces: piecesAtPosition,
          position: currentPosition,
        });
      }

      currentRow += rowStep;
      currentCol += colStep;
    }

    // Handle the final position (toPosition)
    if (toPiece) {
      this.captureMove(fromPosition, toPosition, piece, toPiece, sideEffects);
    } else {
      this.regularMove(fromPosition, toPosition, piece);
    }

    // Mega Charge logic
    const isMegaCharge = Math.abs(rowDiff) > 2 || Math.abs(colDiff) > 2;
    if (isMegaCharge) {
      piece.hasCharged = true;
    }
  }

  captureMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece,
    sideEffects: SideEffect[]
  ) {
    // Collect the capture as a side effect
    sideEffects.push({
      effect: Effect.capture,
      pieces: [toPiece],
      position: toPosition,
    });

    // Move the attacking piece
    this.regularMove(fromPosition, toPosition, piece);
  }

  houseMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece
  ) {
    this.regularMove(fromPosition, toPosition, piece);
    piece.isHousing = true;
  }

  getValidMoves(position: Position, piece: IPiece): IMove[] {
    if (piece.isPoisoned) {
      // The piece is poisoned and cannot move
      return [];
    }
    if (piece.type === PieceType.Tree && piece.isMeadowed) {
      // The Tree is meadowed and cannot move
      return [];
    }
    if (piece.isEntangled) {
      // The animal is entangled and cannot move
      return [];
    }
    const { row, col } = position;
    const height = this.gameState.board.length;
    const width = this.gameState.board[0]?.length || 0;

    // Check if the position is within the board bounds
    if (row < 0 || row >= height || col < 0 || col >= width) {
      throw new Error("Position is out of bounds");
    }

    // Check if the board is initialized
    if (!this.gameState.board[row]) {
      throw new Error("Board is not initialized");
    }

    // Check if the square at the specified position exists
    const square = this.gameState.board[row][col];
    if (!square) {
      throw new Error("Square is undefined");
    }

    if (!piece) {
      return [];
    }

    // Get the valid moves for the piece
    return piece.getValidMovesForPiece(this.gameState.board, position);
  }

  isCheckmate(): boolean {
    throw new Error("Method not implemented.");
  }

  isStalemate(): boolean {
    throw new Error("Method not implemented.");
  }

  switchTurn(): void {
    throw new Error("Method not implemented.");
  }

  undoMove(): void {
    throw new Error("Method not implemented.");
  }

  getEmptySquare(): ISquare {
    return new Square([]);
  }

  emptyBoard() {
    this.gameState.board.forEach((row) => {
      row.forEach((col) => {
        let myArray: IPiece[] = [];
        col.Pieces = myArray;
      });
    });
    //this.notifyGameStateChange(); // DO NOT ADD
  }

  placePiece(piece: IPiece, position: Position) {
    this.gameState.board[position.row][position.col].Pieces.push(piece);
    // TODO BIG MERGE
    this.notifyLocalListeners(); // DO NOT ADD
  }

  // startGame() {
  //   const a = 2;
  //   // assign players
  //   // var startingColour = Math.random();
  //   // king selection
  //   // can be done at the same time
  //   // piece selection
  //   // light first
  // }

  setLightKing(pieceType: PieceType) {
    if (this.gameState.state !== State.PreGame || this.gameState.lightKing)
      throw Error("The game state is incorrect to set light king");
    this.gameState.lightKing = pieceType;
    this.notifyLocalListeners();
  }

  setDarkKing(pieceType: PieceType) {
    if (this.gameState.state !== State.PreGame || this.gameState.darkKing)
      throw Error("The game state is incorrect to set dark king");
    this.gameState.darkKing = pieceType;
    this.notifyLocalListeners();
  }

  setStartingPiece(
    pieceType: PieceType,
    isLight: boolean,
    col: 0 | 1 | 2 | 3 | 4
  ) {
    if (this.gameState.state !== State.PreGame)
      throw Error("The game state is incorrect to st a starting piece");
    this.gameState.board[col][isLight ? 0 : 7].Pieces[0] = new Piece(
      pieceType,
      isLight
    );
    this.notifyLocalListeners();
  }

  // throw this out one
  modifyGameState() {
    this.emptyBoard();
    this.placePiece(new Piece(PieceType.Crane, true), { col: 0, row: 7 });
    this.placePiece(new Piece(PieceType.Rhino, true), { col: 2, row: 0 });
    this.placePiece(new Piece(PieceType.Monkey, true), { col: 3, row: 0 });
    this.placePiece(new Piece(PieceType.Fungi, false), { col: 1, row: 4 });
    this.notifyLocalListeners();
  }
}
