import styles from "./BoardContainer.module.css";
import { useEffect, useState } from "react";
import Board from "./Board";
import ZodiacEngine from "../services/zodiacEngine/zodiacEngine";
import {
  IPiece,
  ISquare,
  IMove,
  Position,
  Mode,
  State,
  PieceType,
} from "../services/zodiacEngine/types";
import { Square } from "../services/zodiacEngine/Square";
import { allPieceTypes } from "../services/zodiacEngine/const";
import { Piece } from "../services/zodiacEngine/Piece";

// Create a single instance of ZodiacEngine
var zodiacEngine = new ZodiacEngine();
var selectedPiece: {
  position: Position;
  piece: IPiece;
} | null;

function BoardContainer() {
  const [lightKing, setLightKingState] = useState(PieceType.Bear);
  const [darkKing, setDarkKingState] = useState(PieceType.Bear);
  const [gameId, setGameId] = useState(zodiacEngine.getGameId());
  const [gameState, setGameState] = useState(zodiacEngine.getGameState());
  const [potentialMoves, setPotentialMoves] = useState([] as IMove[]);

  useEffect(() => {
    console.log("Effect running - subscribing to game state changes");
    const updateUiState = () => {
      setGameState(zodiacEngine.getGameState());
      setGameId(zodiacEngine.getGameId());
    };
    zodiacEngine.subscribeToGameStateChanges(updateUiState);

    // Optional cleanup: Unsubscribe when the component unmounts
    return () => {
      console.log("Effect cleanup - unsubscribing from game state changes");
      zodiacEngine.ungracefulTermination();
      zodiacEngine.unsubscribeToGameStateChanges(updateUiState);
    };
  }, []);

  function OnSquareClick(
    square: ISquare,
    position: Position,
    move?: IMove,
    pieceClicked?: IPiece
  ) {
    //if the square clicked is a move, we should move the piece
    if (move) {
      movePiece(move);
      return;
    }

    // deselect
    if (square.Pieces.length === 0) {
      deselectPiece();
      return;
    }

    if (!pieceClicked) return;

    // get valid moves
    getValidMoves(square, position, pieceClicked!);
  }

  function getValidMoves(
    square: Square,
    position: Position,
    pieceClicked: IPiece
  ) {
    selectedPiece = { piece: pieceClicked, position: position };
    setPotentialMoves(zodiacEngine.getValidMoves(position, pieceClicked));
    //updateState();
  }

  function deselectPiece() {
    setPotentialMoves([]);
    selectedPiece = null;
    //updateState();
  }

  function movePiece(move: IMove) {
    var moveSuccessful = zodiacEngine.makeMove(
      selectedPiece!.position,
      move.position,
      selectedPiece!.piece,
      move.toPiece,
      move.moveType,
      move.sideEffects
    );
    if (moveSuccessful) {
      setPotentialMoves([]);
    } else {
      console.log(`attempted move: ${move}`);
      throw new Error(`Move failed, move is likely invalid`);
    }
  }

  function handleSetupTestBoard() {
    zodiacEngine.modifyGameState();
  }

  const handleLightKingChange = (event: any) => {
    setLightKingState(event.target.value);
  };

  const handleDarkKingChange = (event: any) => {
    setDarkKingState(event.target.value);
  };

  function setLightKing() {
    zodiacEngine.setLightKing(lightKing);
  }

  function setDarkKing() {
    zodiacEngine.setDarkKing(darkKing);
  }

  function liveMode() {
    zodiacEngine.switchToLiveMove();
  }

  function devMode() {
    zodiacEngine.switchToDevMove();
  }

  function mapStringToPieceType(pieceString: string): PieceType {
    switch (pieceString.toLowerCase()) {
      case "bear":
        return PieceType.Bear;
      case "crane":
        return PieceType.Crane;
      case "frog":
        return PieceType.Frog;
      case "fungi":
        return PieceType.Fungi;
      case "goat":
        return PieceType.Goat;
      case "monkey":
        return PieceType.Monkey;
      case "octopus":
        return PieceType.Octopus;
      case "penguin":
        return PieceType.Penguin;
      case "rhino":
        return PieceType.Rhino;
      case "snake":
        return PieceType.Snake;
      case "tree":
        return PieceType.Tree;
      case "virus":
        return PieceType.Virus;
      case "wolf":
        return PieceType.Wolf;
      default:
        throw new Error(`Unknown piece type: ${pieceString}`);
    }
  }

  function getPreGameControls(): JSX.Element {
    const pieceTypes = [
      "Bear",
      "Crane",
      "Frog",
      "Fungi",
      "Goat",
      "Monkey",
      "Octopus",
      "Penguin",
      "Rhino",
      "Snake",
      "Tree",
      "Virus",
      "Wolf",
    ];

    return (
      <>
        <div className={styles.pregame}>
          <select onChange={handleLightKingChange}>
            <option value="">Select light king</option>
            {allPieceTypes.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button onClick={setLightKing}>Set light king</button>
          <p />
          <select onChange={handleDarkKingChange}>
            <option value="">Select dark king</option>
            {allPieceTypes.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button onClick={setDarkKing}>Set dark king</button>
          <div className={styles.pieceIcons}>
            <div className={styles.lightPieces}>
              {pieceTypes.map((piece) => (
                <div
                  key={`${piece}-light`}
                  className={styles.pieceIconContainer}
                >
                  <img
                    src={`/icons/${piece}-light.png`}
                    alt={`${piece}-light`}
                    className={styles.pieceIcon}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, piece, true)}
                  />
                </div>
              ))}
            </div>
            <div className={styles.darkPieces}>
              {pieceTypes.map((piece) => (
                <div
                  key={`${piece}-dark`}
                  className={styles.pieceIconContainer}
                >
                  <img
                    src={`/icons/${piece}-dark.png`}
                    alt={`${piece}-dark`}
                    className={styles.pieceIcon}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, piece, false)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }

  function handleDragStart(
    event: React.DragEvent<HTMLImageElement>,
    piece: string,
    isLight: boolean
  ) {
    const pieceType = mapStringToPieceType(piece);
    event.dataTransfer.setData(
      "piece",
      JSON.stringify({ type: pieceType, isLight })
    );
  }

  function handleDrop(
    event: React.DragEvent<HTMLDivElement>,
    position: Position
  ) {
    event.preventDefault();
    const pieceData = event.dataTransfer.getData("piece");

    const validPieces = [
      '{"type":"Bear","isLight":true}',
      '{"type":"Bear","isLight":false}',
      '{"type":"Crane","isLight":true}',
      '{"type":"Crane","isLight":false}',
      '{"type":"Frog","isLight":true}',
      '{"type":"Frog","isLight":false}',
      '{"type":"Fungi","isLight":true}',
      '{"type":"Fungi","isLight":false}',
      '{"type":"Goat","isLight":true}',
      '{"type":"Goat","isLight":false}',
      '{"type":"Monkey","isLight":true}',
      '{"type":"Monkey","isLight":false}',
      '{"type":"Octopus","isLight":true}',
      '{"type":"Octopus","isLight":false}',
      '{"type":"Penguin","isLight":true}',
      '{"type":"Penguin","isLight":false}',
      '{"type":"Rhino","isLight":true}',
      '{"type":"Rhino","isLight":false}',
      '{"type":"Snake","isLight":true}',
      '{"type":"Snake","isLight":false}',
      '{"type":"Tree","isLight":true}',
      '{"type":"Tree","isLight":false}',
      '{"type":"Wolf","isLight":true}',
      '{"type":"Wolf","isLight":false}',
      '{"type":"Virus","isLight":true}',
      '{"type":"Virus","isLight":false}',
    ];
    if (validPieces.includes(pieceData)) {
      const { type, isLight } = JSON.parse(pieceData);
      zodiacEngine.placePiece(new Piece(type as PieceType, isLight), position);
      //updateState();
    } else {
      return;
    }
  }
  function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
  }

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <button onClick={handleSetupTestBoard}>Set up test state</button>
        <p />
        <button onClick={liveMode} disabled={gameState.mode === Mode.Live}>
          Switch to Live Mode
        </button>
        <button onClick={devMode}>Switch to dev Mode</button>
        <h1>Game Info</h1>
        Mode: {gameState.mode} <br />
        GameId: {gameId} <br />
        State: {gameState.state} <br />
        Light King: {gameState.lightKing} <br />
        Dark King: {gameState.darkKing} <br />
        <br />
        {zodiacEngine.getGameState().state === State.PreGame
          ? getPreGameControls()
          : ""}
      </div>
      <div>
        <Board
          gameState={gameState}
          uiState={{
            potentialMoves: potentialMoves,
            selectedPiece: selectedPiece,
          }}
          onSquareClick={OnSquareClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        />
      </div>
    </div>
  );
}

export default BoardContainer;
