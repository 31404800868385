import {
  allPieceTypes,
  directions,
  largePieceTypes,
  predatorPieceTypes,
} from "../const";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  MoveType,
  ISquare,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class CraneMovement extends BaseMovement implements PieceMovement {
  capturablePieceTypes: PieceType[] = allPieceTypes.filter(
    (piece) =>
      !largePieceTypes.includes(piece) && !predatorPieceTypes.includes(piece)
  );

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];
    const movementRange = 2;

    for (const direction of directions) {
      for (let i = 1; i <= movementRange; i++) {
        const toPosition = {
          col: position.col + direction[0] * i,
          row: position.row + direction[1] * i,
        };

        if (!this.onBoard(boardState, toPosition)) break;

        const toSquare = boardState[toPosition.row][toPosition.col];
        const squareInfo = this.getSquareInfo(toSquare, piece.isLight);
        if (squareInfo.basicSquareInfo) {
          var move = this.getMove(
            position,
            toPosition,
            squareInfo.basicSquareInfo,
            piece,
            this.capturablePieceTypes
          );

          if (move) {
            possibleMoves.push(move);
            console.log("Generated move:", move);
          } else if (
            squareInfo.basicSquareInfo.piece &&
            squareInfo.basicSquareInfo.piece.type === PieceType.Tree &&
            squareInfo.basicSquareInfo.piece.isLight !== piece.isLight
          ) {
            const houseMove: IMove = {
              position: toPosition,
              toPiece: toSquare.Pieces[0],
              moveType: MoveType.house,
              sideEffects: [],
            };
            possibleMoves.push(houseMove);
            console.log("Generated move (house):", houseMove);
          }
        }
      }
    }

    return possibleMoves;
  }
}
