import { allPieceTypes, directions } from "../const";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  MoveType,
  ISquare,
  SideEffect,
  Effect,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class FungiMovement extends BaseMovement implements PieceMovement {
  capturablePieceTypes: PieceType[] = allPieceTypes.filter(
    (pieceType) => pieceType !== PieceType.Fungi
  );

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    for (const direction of directions) {
      const newPosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      if (!this.onBoard(boardState, newPosition)) {
        continue;
      }

      const newSquare = boardState[newPosition.row][newPosition.col];
      if (!newSquare) {
        continue;
      }

      const squareInfo = this.getSquareInfo(newSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          newPosition,
          squareInfo.basicSquareInfo,
          piece,
          this.capturablePieceTypes
        );

        if (move) {
          // Add side effects for adjacent squares
          const sideEffects = this.calculateSideEffects(
            newPosition,
            boardState
          );
          move.sideEffects = sideEffects;
          possibleMoves.push(move);
        } else if (
          squareInfo.basicSquareInfo.isAlly &&
          squareInfo.basicSquareInfo.piece?.type === PieceType.Tree
        ) {
          // House move
          const houseMove: IMove = {
            position: newPosition,
            toPiece: squareInfo.basicSquareInfo.piece,
            moveType: MoveType.house,
            sideEffects: [],
          };

          // Add side effects for house move
          const sideEffects = this.calculateSideEffects(
            newPosition,
            boardState
          );
          houseMove.sideEffects = sideEffects;

          possibleMoves.push(houseMove);
        }
      }
    }

    return possibleMoves;
  }

  // Helper method to calculate side effects
  private calculateSideEffects(
    newPosition: Position,
    boardState: ISquare[][]
  ): SideEffect[] {
    const sideEffects: SideEffect[] = [];
    const adjacentDirections = [
      [-1, -1],
      [0, -1],
      [1, -1],
      [-1, 0],
      [1, 0],
      [-1, 1],
      [0, 1],
      [1, 1],
    ];

    for (const dir of adjacentDirections) {
      const adjacentPosition = {
        col: newPosition.col + dir[0],
        row: newPosition.row + dir[1],
      };
      if (this.onBoard(boardState, adjacentPosition)) {
        const adjacentSquare =
          boardState[adjacentPosition.row][adjacentPosition.col];
        const adjacentPiece = adjacentSquare.Pieces[0];
        if (adjacentPiece && adjacentPiece.type === PieceType.Tree) {
          sideEffects.push({
            position: adjacentPosition,
            pieces: adjacentSquare.Pieces,
            effect: Effect.meadow,
          });
        }
      }
    }

    return sideEffects;
  }
}
